import axios from "axios";
import { BASE_URL } from "config";
import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import { Link } from "@mui/material";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import Button from "@mui/material/Button";
import axiosInstance from "Axiosinstance";
import { toast } from "react-toastify";

function Founderdetails({ tagnumber, setmodal, foundermodal }) {
  const [foundetails, setfounddetails] = useState([]);
  const generateerror = err => {
    toast.error(err, {
      position: "top-center",
    });
  };

  // function to handel the error and dispaly a alert
  const errorHandeling = er => {
    if (er?.response?.data?.authError) {
      generateerror(
        er?.response?.data?.msg?.message ||
          er?.response?.data?.msg ||
          "unexpected error ocured"
      );
    } else {
      generateerror(er?.response?.data?.message || "unexpected error ocured");
    }
  };

  const getfounderdetails = () => {
    axiosInstance
      .get(`/api/getfounderdetails/${tagnumber}`)
      .then(({ data }) => {
      
        setfounddetails(data?.founderDetails);
      })
      .catch(er => {
        console.log(er);
        errorHandeling(er);
      });
  };
  const column = [
    {
      dataField: "_id",
      text: "",
      formatter: (cell, row, rowIndex) => rowIndex + 1, // Display row number starting from 1
      headerStyle: () => {
        return { width: "50px" }; // Adjust the width as needed
      },
    },
    {
      dataField: "founder_name",
      text: "Full Name",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.foundername ? (
              <Link to="#" className="text-dark">
                {product?.foundername}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },

    {
      dataField: "founddate",
      text: "Date",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.founddate ? (
              <Link to="#" className="text-dark">
                {new Date(product?. founddate).toLocaleString()}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "founderphone",
      text: "Contact",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.founderphone ? (
              <Link to="#" className="text-dark">
                {product?.founderphone}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
    {
      dataField: "founderemail",
      text: "Email",
      formatter: (cellContent, product) => {
        return (
          <h5 className="font-size-14 mb-1">
            {product?.founderemail ? (
              <Link to="#" className="text-dark">
                {product?.founderemail}
              </Link>
            ) : (
              <Link to="#" className="text-dark">
                <p> No </p>
              </Link>
            )}
          </h5>
        );
      },
    },
  ];
  useEffect(() => {
    getfounderdetails();
  }, []);
  return (
    <div>
      <Modal isOpen={foundermodal}>
        <ModalHeader toggle={() => setmodal(false)} tag="h4">
          Founder Details
        </ModalHeader>
        <ModalBody>
          {foundetails?.length ? (
            <>
              <div
                style={{ backgroundColor: "white", marginTop: "2rem" }}
                className="table-responsive"
              >
                <BootstrapTable
                  keyField="_id"
                  data={foundetails}
                  columns={column}
                  pagination={paginationFactory()}
                />
              </div>
            </>
          ) : (
            <p>No founder details available</p>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Founderdetails;

