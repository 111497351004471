import React, { useState, useEffect } from "react";
import axios from "axios";
import { withRouter, Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  Button,
  ModalHeader,
  ModalBody,
  Label,
} from "reactstrap";
import CouponForm from "../CouponFrom";
import CouponFormComponent from "./CouponFormComponent";
import "../Coupons/index.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import { BASE_URL } from "../../config";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { DateTimePicker, MobileDateTimePicker } from "@mui/x-date-pickers";
import { get } from "lodash";
import axiosInstance from "Axiosinstance";
// const columns = [
//   {
//     dataField: "id",
//     text: "ID",
//     sort: true,
//   },
//   {
//     dataField: "name",
//     text: "Name",
//     sort: true,
//   },
//   {
//     dataField: "email",
//     text: "Email",
//     sort: true,
//   },
//   // add more columns as needed
// ];

function MyTable() {
  const [productList, setProductList] = useState([]);
  // const [couponDetailList, setCouponDetailList] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const [isProductDetailModalOpen, setIsProductDetailModalOpen] =
    useState(false);
  const [isCouponDetailFormModalOpen, setIsCouponDetailFormModalOpen] =
    useState(false);

  const [selectedCouponInfo, setSelectedCouponInfo] = useState(null);
  const [isEndDateDialogOpen, setIsEndDateDialogOpen] = useState(false);
  const [dtpEndDate, setDtpEndDate] = useState(moment().endOf("day"));

  const openEndDateDialog = couponInfo => {
    if (couponInfo) {
      // console.log("coupon infor new",couponInfo);
      const endDate = moment(new Date(couponInfo.end_date));
      setDtpEndDate(endDate);
      // console.log("Seto",dtpEndDate);
      // console.log("Setdb",endDate);
      setSelectedCouponInfo(couponInfo);
      setIsEndDateDialogOpen(true);
    } else {
      toast.warn("Something went wrong!!! Please try after sometimes.");
    }
  };

  const closeEndDateDialog = () => {
    setDtpEndDate(moment().endOf("day"));
    setIsEndDateDialogOpen(false);
    setSelectedCouponInfo(null);
  };

  const handleEndDateDialogUpdateBtnClick = async () => {
    try {
      // console.log(selectedCouponInfo);
      const startDate = moment(new Date(selectedCouponInfo.start_date));
      if (dtpEndDate.unix() <= startDate.unix()) {
        return toast.warn("End date should be greater than start date.");
      }
      if (dtpEndDate.unix() <= moment().unix()) {
        return toast.warn(
          "End date time should be greater than current date time."
        );
      }

      const res = await axiosInstance({
        url: `/api/coupon/editexpiry`,
        method: "post",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
        data: {
          couponCode: selectedCouponInfo.coupon_code,
          updatedEndDate: dtpEndDate.unix(),
        },
      });
      if (res && res.data) {
        if (res.data.isError) {
          return toast.error(res.data.msg);
        } else {
          await hGetAllCouponDetails();
          closeEndDateDialog();
          return toast.success("End date updated succesfully.");
        }
      } else {
        console.error("/api/coupon/editexpiry :: Exception", res); //todo: remove after testing
        throw "Invalid response.";
      }
    } catch (error) {
      console.error("/api/coupon/editexpiry :: Exception", error);
      return toast.error("Server error. Please try after sometimes.");
    }
  };

  const openCouponDetailFormModal = () => {
    setIsCouponDetailFormModalOpen(true);
  };

  const handleDeleteCouponBtnClick = async couponInfo => {
    try {
      const res = await axiosInstance({
        url: `/api/coupons/${couponInfo.coupon_code}`,
        method: "DELETE",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      });
      if (res) {
        toast.success("Coupon code deleted successfully");
        await hGetAllCouponDetails();
      } else {
        console.error("/api/coupon/deletecouponcode :: Exception", res);
        throw "Invalid response.";
      }
    } catch (error) {
      console.error("/api/coupon/deletecouponcode :: Exception", error);
      return toast.error("Server error. Please try after sometimes.");
    }
  };

  const openProductDetailModal = couponInfo => {
    if (
      couponInfo &&
      couponInfo.product_list &&
      couponInfo.product_list.length > 0
    ) {
      setSelectedCouponInfo(couponInfo);
      setIsProductDetailModalOpen(true);
      return;
    } else {
      return toast.warn("Product details not found!!!");
    }
  };

  const closeProductDetailModal = () => {
    setSelectedCouponInfo(null);
    setIsProductDetailModalOpen(false);
  };

  const couponDetailFormSubmitAction = async () => {
    closeCouponDetailFormModal();
    await hGetAllCouponDetails();
  };

  const closeCouponDetailFormModal = () => {
    setIsCouponDetailFormModalOpen(false);
  };

  async function hGetAllProducts() {
    try {
      if (productList && productList.length > 0) {
        return true;
      }
      const res = await axiosInstance({
        url: `/getallproducts`,
        method: "get",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      });
      if (res && res.data) {
        return setProductList(res.data);
      } else {
        console.error("/getallproducts :: Exception", res); //todo: remove after testing
        throw "Invalid response.";
      }
    } catch (error) {
      console.error("/getallproducts :: Exception", error);
      return toast.error("Server error. Please try after sometimes.");
    }
  }

  async function hGetAllCouponDetails() {
    try {
      const res = await axiosInstance({
        url: `/coupondetails`,
        method: "get",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("authUser")).token,
        },
      });
      if (res && res.data) {
        if (res.data.coupons && res.data.coupons.length > 0) {
          setTableData(res.data.coupons.reverse());
          // setCouponDetailList(res.data.coupons.reverse());
          return true;
        } else {
          return toast.warn("Coupon Details Not Found!!!");
        }
      } else {
        console.error("/getallproducts :: Exception", res); //todo: remove after testing
        throw "Invalid response.";
      }
    } catch (error) {
      console.error("/getallproducts :: Exception", error);
      return toast.error("Server error. Please try after sometimes.");
    }
  }

  useEffect(() => {
    (async () => {
      try {
        await Promise.all([hGetAllProducts(), hGetAllCouponDetails()]);
      } catch (error) {
        console.error("Page Load :: Exception", error);
        toast.error("Server error. Please try after sometimes.");
      }
    })();
  }, []);

  const filteredData = tableData.filter(coupon =>
    coupon.coupon_code.toLowerCase().includes(searchKey.toLowerCase())
  );

  const columns = [
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, user) => {
        const now = moment().unix();
        const endDateStamp = moment(new Date(user.end_date))
          .endOf("day")
          .unix();
        if (now >= endDateStamp || user.discount_value == 0) {
          return <span style={{ color: "red" }}>Expired</span>;
        } else {
          return <span style={{ color: "green" }}>Active</span>;
        }
      },
    },
    { dataField: "company_name", text: "Company Name" },
    { dataField: "discount_type", text: "Discount Type" },
    { dataField: "coupon_code", text: "Coupon code" },
    { dataField: "coupon_type", text: "Coupon Type" },
    { dataField: "user_email", text: "User Email" },
    { dataField: "coupon_usage", text: "Coupoun Usage" },

    {
      dataField: "created_date",
      text: "Activation Date",
      formatter: (cellContent, user) => {
        return (
          <span>{`${moment(new Date(user.created_date)).format(
            "DD-MM-YYYY HH:mm:ss"
          )}`}</span>
        );
      },
    },
    {
      dataField: "start_date",
      text: "Start Date",
      formatter: (cellContent, user) => {
        return (
          <span>{`${moment(new Date(user.start_date)).format(
            "DD-MM-YYYY HH:mm:ss"
          )}`}</span>
        );
      },
    },
    {
      dataField: "end_date",
      text: "End Date",
      formatter: (cellContent, user) => {
        return (
          <div className="d-flex gap-3">
            <span>{`${moment(new Date(user.end_date)).format(
              "DD-MM-YYYY HH:mm:ss"
            )}`}</span>
            <Link
              className="text-success"
              to="#"
              onClick={() => openEndDateDialog(user)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
            </Link>
          </div>
        );
      },
    },
    { dataField: "max_redeem_count", text: "Redmeption Limit" },
    { dataField: "min_cart_value", text: "Cart Value" },
    { dataField: "discount_value", text: "Discount Value" },
    {
      dataField: "product_list",
      text: "Products",
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <h2 href="#" onClick={() => openProductDetailModal(user)}>
            {user.product_list.length}
          </h2>
        </div>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Action",
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link
            className="text-failure"
            to="#"
            onClick={() => handleDeleteCouponBtnClick(user)}
          >
            <i className="mdi mdi-trash-can font-size-18" id="edittooltip"></i>
          </Link>
        </div>
      ),
    },
  ];

  const pageoptions = {
    sizePerPage: 10,
    totalSize: tableData.length,
  };

  const renderTable = () => {
    if (searchKey) {
      return (
        <BootstrapTable
          keyField="coupon_code"
          className="my-custom-table"
          data={filteredData}
          columns={columns}
          pagination={paginationFactory(pageoptions)}
          responsive
        />
      );
    } else {
      return (
        <BootstrapTable
          keyField="coupon_code"
          className="my-custom-table"
          data={tableData}
          columns={columns}
          pagination={paginationFactory(pageoptions)}
          responsive
        />
      );
    }
  };

  return (
    <>
      {/* End Date Dialog */}
      <Dialog
        name="EndDateUpdateDialog"
        open={isEndDateDialogOpen}
        onClose={closeEndDateDialog}
      >
        <DialogTitle>Update Coupon End Date</DialogTitle>
        <DialogContent>
          <DialogContentText>Select new End Date</DialogContentText>
          <MobileDateTimePicker
            sx={{ width: "100%", marginTop: 1 }}
            label="End Date"
            value={dtpEndDate}
            defaultValue={moment().endOf("day")}
            minDate={moment()}
            onChange={(value, context) => {
              setDtpEndDate(value);
            }}
          />
          {selectedCouponInfo && selectedCouponInfo.endDateError ? (
            <span
              style={{ color: "red" }}
            >{`${selectedCouponInfo.endDateError}`}</span>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEndDateDialogUpdateBtnClick}>Update</Button>
          <Button onClick={closeEndDateDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <Modal
        name="ProductDetailModal"
        isOpen={isProductDetailModalOpen}
        toggle={closeProductDetailModal}
      >
        <ModalHeader toggle={closeProductDetailModal}>
          Product Details
        </ModalHeader>
        <ModalBody>
          {selectedCouponInfo ? (
            <table cellPadding={7} cellSpacing={3} style={{ width: "100%" }}>
              <tr style={{ color: "gray" }}>
                <th>Product Name</th>
                <th>Discount Value</th>
              </tr>
              {(selectedCouponInfo.product_list || []).map(product => (
                <>
                  <tr>
                    <td>{product.name}</td>
                    <th>
                      {selectedCouponInfo.coupon_type === "value" ? "₹" : ""}
                      {product.value}
                      {selectedCouponInfo.coupon_type === "percentage"
                        ? "%"
                        : ""}
                    </th>
                  </tr>
                </>
              ))}
            </table>
          ) : (
            <span>Product Details Not Found.</span>
          )}
        </ModalBody>
        <div className="modal-footer">
          <Button color="secondary" onClick={closeProductDetailModal}>
            Close
          </Button>
        </div>
      </Modal>

      <Modal
        name="CouponDetailFormModal"
        centered={true}
        scrollable={true}
        size={"lg"}
        isOpen={isCouponDetailFormModalOpen}
        toggle={closeCouponDetailFormModal}
      >
        <ModalHeader toggle={closeCouponDetailFormModal}>
          Coupon Details
        </ModalHeader>
        <ModalBody>
          <CouponFormComponent
            couponFormParentSubmitAction={couponDetailFormSubmitAction}
          />
        </ModalBody>
      </Modal>

      <Card>
        <CardBody>
          <div style={{ marginTop: "7.5rem" }}>
            <Button onClick={() => openCouponDetailFormModal()}>
              Open Coupon Generation Form
            </Button>
            <div
              style={{ float: "right" }}
              className="search-box ms-2 mb-2 d-inline-block"
            >
              <div className="position-relative">
                <div className="input-group">
                  <div className="form-outline">
                    <input
                      type="search"
                      id="form1"
                      placeholder="Search By Coupon Code"
                      className="form-control"
                      name="searchkey"
                      value={searchKey}
                      // onChange={this.changeHandler} value={searchKey}
                      onChange={event => setSearchKey(event.target.value)}
                    />
                  </div>
                </div>
                <i className="bx bx-search-alt search-icon" />
              </div>
            </div>
            {/* <div>
              <BootstrapTable
                keyField="id"
                data={tableData}
                columns={columns}
                pagination={paginationFactory()}
              />
            </div> */}
            <Row style={{ width: "100%" }}>
              <Col>
                <div
                  className="custom-table-container"
                  // style={{ width: "100%" }}
                >
                  {renderTable()}
                </div>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default MyTable;
