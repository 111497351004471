//Type of returns that are allowed in the crm

import { BASE_URL } from "config"

export const NORMAL_RETURN = "normal" // for dolphin products having qr and mac address

export const ONLY_MACID = "onlymacid" // for dolphin products having only mac address

export const TAGNUMBER_DAMAGED = "tagnumberdamaged" // for dolphin products whose tagnumber are damaged

export const QR_PRODUCTS = "qrproducts" // for all qr products

//to get a dummy macid,tagnumber,returncode,dispatchcode during testing in local environment only
export const local = BASE_URL === "http://localhost:3333" ?  true : false 