import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';

function Loader() {
  return (
    <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
        <CircularProgress />
    </div>
  )
}

export default Loader
