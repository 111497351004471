import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import axiosInstance from "Axiosinstance";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  ModalFooter,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import MiniWidget from "../Dashboard/mini-widget";
import { Formik, Field, Form, ErrorMessage } from "formik";
// import * as Yup from "yup";
import Select from "react-select";
import MuiPhoneNumber from "material-ui-phone-number";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateAdmins = () => {
  // STATES
  const [reports, setReports] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingPass, setIsEditingPass] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    countryCode: "",
    accessPermission: [],
  });
  const [userPhoneNo, setUserPhoneNo] = useState("");
  const [userPhoneCountryCode, setUserPhoneCountryCode] = useState("");
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deletingAdminEmail, setDeletingAdminEmail] = useState("");
  const [editingAdminEmail, setEditingAdminEmail] = useState("");

  // FUNCTIONS
  const toggleModal = () => {
    setIsOpenModal(!isOpenModal);
    setIsEditing(false);
    setIsEditingPass(false);
    setUser({
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      countryCode: "",
      accessPermission: [],
    });
    setSelectedOptions([]);
    setSelectedAccessPerminssion([]);
    setUserPhoneNo("");
    setEditingAdminEmail("");
  };
  const editModalOpen = user => {
    setUser({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone,
      countryCode: user.phonecountrycode,
      accessPermission: user.activitiesAccess,
    });
    setUserPhoneNo(user.phone);
    setUserPhoneCountryCode(user.phonecountrycode);
    setSelectedOptions(
      user.activitiesAccess.map(item => {
        return {
          value: item,
          label: item,
        };
      })
    );
    setSelectedAccessPerminssion(user.activitiesAccess);
    setEditingAdminEmail(user.email);
    setIsEditing(true);
    setIsOpenModal(!isOpenModal);
  };
  const editPasswordModalOpen = user => {
    setUser({
      firstName: user.first_name,
      lastName: user.last_name,
      email: user.email,
      phone: user.phone,
      countryCode: user.phonecountrycode,
      accessPermission: user.activitiesAccess,
    });
    setUserPhoneNo(user.phone);
    setUserPhoneCountryCode(user.phonecountrycode);
    setSelectedOptions(
      user.activitiesAccess.map(item => {
        return {
          value: item,
          label: item,
        };
      })
    );
    setSelectedAccessPerminssion(user.activitiesAccess);
    setEditingAdminEmail(user.email);
    setIsEditingPass(true);
    setIsOpenModal(!isOpenModal);
  };
  const openMadalToAddAdmin = () => {
    setIsOpenModal(true);
  };

  const toggleDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
    setDeletingAdminEmail("");
  };
  const onDeleteUserAlert = email => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
    setDeletingAdminEmail(email);
  };
  const handleUserDelete = async () => {
    const { data } = await axiosInstance.post(
      `/api/deleteCRMAdmin/${deletingAdminEmail}`
    );
    console.log("data: ", data);
    if (!data?.isError) {
      toast.success(data?.msg, {
        position: "top-center",
      });
      window.location.reload();
    } else if (data?.isError) {
      toast.error(data?.msg, {
        position: "top-center",
      });
    }
  };
  useEffect(() => {
    const fetchAdminsData = async () => {
      const { data } = await axiosInstance.post(`/api/getAllAdmins`);
      setAdmins(data?.data);
      setReports([
        {
          icon: "bx bx-copy-alt",
          title: "All Admins",
          value: data?.totalCount?.toString(),
          badgeValue: "+ 0.2%",
          color: "success",
          desc: "Total Number of Admins",
        },
      ]);
    };
    fetchAdminsData();
  }, []);

  const contactListColumns = [
    {
      text: "Id",
      dataField: "uid",
      sort: true,
      // hidden: true,
      formatter: (cellContent, user) => <>{user.uid}</>,
    },
    {
      text: "Name",
      dataField: "first_name",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {user.first_name}
            </Link>
          </h5>
        </>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      formatter: (cellContent, user) => (
        <span className={`${user.isVerify === "0" ? "text-red" : ""}`}>
          {user.email}
        </span>
      ),
    },
    {
      dataField: "phone",
      text: "Phone",
      sort: true,
    },
    {
      dataField: "createDate",
      text: "Created On",
      sort: true,
      formatter: (cellContent, user) => (
        <>
          <h5 className="font-size-14 mb-1">
            <Link to="#" className="text-dark">
              {new Date(user.date).toLocaleDateString()}
            </Link>
          </h5>
        </>
      ),
    },
    {
      dataField: "menu",
      // isDummyField: true,
      editable: false,
      text: "Access Permissions",
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          {user?.role === "superadmin" ? (
            <h5
              className="font-size-14 mb-1"
              style={{ color: "green", fontWeight: "bold" }}
            >
              Superadmin
            </h5>
          ) : (
            <div>
              {user?.activitiesAccess.map(item => {
                return (
                  <h5 key={item} className="font-size-12 m-1">
                    {item}
                  </h5>
                );
              })}
            </div>
          )}
        </div>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "Actions",
      formatter: (cellContent, user) => (
        <div className="d-flex gap-2">
          <i
            className="mdi mdi-pencil font-size-18 text-success"
            style={{ cursor: "pointer" }}
            id="edittooltip"
            onClick={() => editModalOpen(user)}
          ></i>

          <i
            className="mdi mdi-lock-reset font-size-20 text-info"
            style={{ cursor: "pointer" }}
            id="edittooltip"
            onClick={() => {
              editPasswordModalOpen(user);
            }}
          ></i>
          <i
            className="mdi mdi-delete font-size-18 text-danger"
            style={{ cursor: "pointer" }}
            id="edittooltip"
            onClick={() => onDeleteUserAlert(user.email)}
          ></i>
        </div>
      ),
    },
  ];

  const pageOptions = {
    sizePerPage: 20,
    totalSize: admins?.length,
    custom: true,
  };

  const options = [
    { value: "User Management", label: "User Management" },
    { value: "Tag Management", label: "Tag Management" },
    { value: "Dispatch Management", label: "Dispatch Management" },
    { value: "LNF Management", label: "LNF Management" },
    { value: "Return Management", label: "Return Management" },
    {
      value: "MAC Address Pairing Management",
      label: "MAC Address Pairing Management",
    },
    { value: "Coupon Management", label: "Coupon Management" },
    { value: "Tag Generation Management", label: "Tag Generation Management" },
    { value: "Billing Management", label: "Billing Management" },
    { value: "Visualization Management", label: "Visualization Management" },
  ];
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedAccessPerminssion, setSelectedAccessPerminssion] = useState(
    []
  );
  const SelectField = ({ options, field, form }) => {
    return (
      <Select
        options={options}
        isMulti
        name={field.name}
        value={selectedOptions}
        onChange={option => {
          setSelectedOptions(option);
          const values = option.map(item => item.value);
          form.setFieldValue(field.name, values);
          setSelectedAccessPerminssion(values);
        }}
        onBlur={() => form.setFieldTouched(field.name, true)}
      />
    );
  };
  // console.log("showingAccess: ", showingAccess);
  return (
    <>
      <div className="page-content">
        {/* DB CArds */}
        <Row>
          <Col xl="12">
            <Row>
              <MiniWidget reports={reports} />
            </Row>
          </Col>
        </Row>

        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Admins" breadcrumbItem="Admins List" />
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <div className="mt-4">
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="uid"
                      columns={contactListColumns}
                      data={admins}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="uid"
                          columns={contactListColumns}
                          data={admins}
                        >
                          {toolkitprops => (
                            <React.Fragment>
                              <Row className="mb-2 justify-content-end">
                                <Col sm="6">
                                  <div className="text-sm-end">
                                    <Button
                                      color="primary"
                                      className="font-16 btn-block btn btn-primary"
                                      onClick={openMadalToAddAdmin}
                                      style={{ marginRight: "4px" }}
                                    >
                                      Create Admin
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitprops.baseProps}
                                      {...paginationTableProps}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={false}
                                      responsive
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      {/* ADD & EDIT Admin Modal */}
      <Modal isOpen={isOpenModal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal} tag="h4">
          {!!isEditing
            ? "Edit Admin"
            : isEditingPass
            ? "Edit Password"
            : "Add Admin"}
        </ModalHeader>
        <ModalBody>
          <Formik
            enableReinitialize={true}
            initialValues={{
              firstName: (user && user.firstName) || "",
              lastName: (user && user.lastName) || "",
              email: (user && user.email) || "",
              password: (user && user.password) || "",
              phone: (user && user.phone) || "",
              countryCode: (user && user.countryCode) || "",
              accessPermission: (user && user.accessPermission) || [],
            }}
            onSubmit={async values => {
              if (isEditing) {
                // console.log("isEditing values: ", values);
                values.countryCode = userPhoneCountryCode;
                values.phone = userPhoneNo;
                if (
                  !values.firstName ||
                  !values.lastName ||
                  !values.phone ||
                  !values.email ||
                  !values.accessPermission.length ||
                  !values.countryCode
                ) {
                  toast.error("Incomple Parameters !", {
                    position: "top-center",
                  });
                } else {
                  const { data } = await axiosInstance.post(
                    `/api/updateCRMAdmin/${editingAdminEmail}`,
                    values
                  );
                  console.log("data: ", data);
                  if (!data?.isError) {
                    toast.success(data?.msg, {
                      position: "top-center",
                    });
                    window.location.reload();
                  } else if (data?.isError) {
                    toast.error(data?.msg, {
                      position: "top-center",
                    });
                  }
                }
              } else if (isEditingPass) {
                // console.log("isEditing values: ", values);
                values.countryCode = userPhoneCountryCode;
                values.phone = userPhoneNo;
                if (!values.email || !values.password) {
                  toast.error("Incomple Parameters !", {
                    position: "top-center",
                  });
                } else {
                  const { data } = await axiosInstance.post(
                    `/api/updatePasswordCRMAdmin/${editingAdminEmail}`,
                    { newPassword: values.password }
                  );
                  console.log("data: ", data);
                  if (!data?.isError) {
                    toast.success(data?.msg, {
                      position: "top-center",
                    });
                    window.location.reload();
                  } else if (data?.isError) {
                    toast.error(data?.msg, {
                      position: "top-center",
                    });
                  }
                }
              } else {
                values.countryCode = userPhoneCountryCode;
                values.phone = userPhoneNo;
                values.accessPermission = selectedAccessPerminssion;
                if (
                  !values.firstName ||
                  !values.lastName ||
                  !values.phone ||
                  !values.email ||
                  !values.countryCode ||
                  !values.accessPermission.length ||
                  !values.password
                ) {
                  toast.error("Incomple Parameters !", {
                    position: "top-center",
                  });
                } else {
                  const { data } = await axiosInstance.post(
                    `/api/createCRMAdminDetails`,
                    values
                  );
                  console.log("data: ", data);
                  if (!data?.isError) {
                    toast.success(data?.status, {
                      position: "top-center",
                    });
                    window.location.reload();
                  } else if (data?.isError) {
                    toast.error(data?.error, {
                      position: "top-center",
                    });
                  }
                }
              }
            }}
          >
            {({ errors, status, touched }) => (
              <Form>
                <Row>
                  <Col className="col-12">
                    {!isEditingPass && (
                      <>
                        <div className="mb-3">
                          <Label className="form-label">First Name</Label>
                          <Field
                            name="firstName"
                            type="text"
                            className={
                              "form-control" +
                              (errors.firstName && touched.firstName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="firstName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Last Name</Label>
                          <Field
                            name="lastName"
                            type="text"
                            className={
                              "form-control" +
                              (errors.lastName && touched.lastName
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="lastName"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">Phone</Label>
                          <MuiPhoneNumber
                            placeholder="Phone"
                            name="phone"
                            className={
                              "form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                            defaultCountry={"in"}
                            value={`${userPhoneCountryCode} ${userPhoneNo}`}
                            onChange={value => {
                              const code = value?.split(" ")[0];
                              setUserPhoneCountryCode(code);
                              setUserPhoneNo(value?.slice(code?.length).trim());
                            }}
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="mb-3">
                          <Label className="form-label">
                            Access Permission
                          </Label>
                          <Field
                            component={SelectField}
                            name="accessPermission"
                            options={options}
                          />
                          <ErrorMessage
                            name="accessPermission"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </>
                    )}
                    {/* email */}
                    <div className="mb-3">
                      <Label className="form-label">Email</Label>
                      <Field
                        name="email"
                        type="text"
                        className={
                          "form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {!isEditing ? (
                      <div className="mb-3">
                        <Label className="form-label">Password</Label>
                        <Field
                          name="password"
                          type="password"
                          className={
                            "form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    ) : null}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <button
                        type="submit"
                        className="btn btn-success save-user"
                      >
                        Save
                      </button>
                    </div>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>

      {/* Delete amdin modal */}
      <Modal isOpen={isOpenDeleteModal} fade={false} toggle={toggleDeleteModal}>
        <ModalHeader>Are you sure? You want to detele.</ModalHeader>
        <ModalFooter>
          <Button color="primary" onClick={handleUserDelete}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={toggleDeleteModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      {/* TOAST NOTIFICATIONS */}
      <ToastContainer />
    </>
  );
};

export default CreateAdmins;
