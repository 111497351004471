import React, { Component } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//Import Images
import avatar1 from "../../assets/images/users/avatar-2.jpg";
import "./dashboard.css";
import { Link } from "react-router-dom";

class CardUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settings_Menu: false,
      user: {},
    };
    this.toggleSettings = this.toggleSettings.bind(this);
  }

  componentDidMount() {
    this.fetchAdminDetails();
  }

  fetchAdminDetails = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("data: ", user);
    this.setState({ user: user });
  };
  //Setting Menu
  toggleSettings() {
    this.setState(prevState => ({
      settings_Menu: !prevState.settings_Menu,
    }));
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <Row>
                  <Col lg="4">
                    <div className="d-flex gap-2">
                      <Link to="/profile" className="profile-img-div">
                        {/* <img
                          src={avatar1}
                          alt="profileImage"
                          className="avatar-md rounded-circle img-thumbnail"
                        /> */}
                        <span className="profile-text"> {this.state.user.name?.split("")[0]?.toUpperCase()} </span>
                      </Link>
                      <div className="flex-grow-1 align-self-center">
                        <div className="text-muted">
                          <p className="mb-2">Welcome to dashboard</p>
                          <h5 className="mb-1">{this.state.user.name}</h5>
                          <p className="mb-0">{this.state.user.role}</p>
                        </div>
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg="4" className="align-self-center">
                    <div className="text-lg-center mt-4 mt-lg-0">
                      <Row>
                        <Col xs="4">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              Total Projects
                            </p>
                            <h5 className="mb-0">48</h5>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              Projects
                            </p>
                            <h5 className="mb-0">40</h5>
                          </div>
                        </Col>
                        <Col xs="4">
                          <div>
                            <p className="text-muted text-truncate mb-2">
                              Clients
                            </p>
                            <h5 className="mb-0">18</h5>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col> */}

                  {/* <Col lg="4" className="d-none d-lg-block">
                    <div className="clearfix mt-4 mt-lg-0">
                      <Dropdown
                        isOpen={this.state.settings_Menu}
                        toggle={this.toggleSettings}
                        className="float-end"
                      >
                        <DropdownToggle
                          tag="button"
                          className="btn btn-primary"
                        >
                          <i className="bx bxs-cog align-middle me-1"></i>{" "}
                          Setting
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-end">
                          <DropdownItem href="#">Action</DropdownItem>
                          <DropdownItem href="#">Another action</DropdownItem>
                          <DropdownItem href="#">Something else</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </Col> */}
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CardUser;
