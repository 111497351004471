import React, { Component } from "react";
import PropTypes from "prop-types";
import { Col, Card, CardBody } from "reactstrap";

class MiniWidget extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.reports.map((report, key) => (
          <Col sm="3" key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span
                      className="avatar-title rounded-circle font-size-18"
                      // style={{ backgroundColor: `${report.bgColor} !important` }}
                      style={{ backgroundColor: report.bgColor }}
                    >
                      <i
                        style={{ color: report.color }}
                        className={report.icon}
                      />
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">{report.title}</h5>
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    {report.value}{" "}
                    <i className="mdi mdi-chevron-up ms-1 text-success" />
                  </h4>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

MiniWidget.propTypes = {
  reports: PropTypes.array,
};

export default MiniWidget;
