import React, { Fragment, useState, useEffect } from "react";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import moment from "moment";
import {
    Box, Grid, Button, LinearProgress, Paper, FormLabel, RadioGroup, FormControlLabel,
    Radio, TextField, Stack, Divider, Modal,
    List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    Checkbox, InputAdornment,
    CardContent,
    CardActions
} from '@mui/material';

import { styled } from '@mui/material/styles';
import Typography from "@mui/material/Typography";
import { Item } from "../../components/Common/UiComponents";
import { Card } from "reactstrap";
import { fontSize } from "@mui/system";


function AddProduct(props) {

    const [checked, setChecked] = React.useState([0]);
    const [productList, setProductList] = useState([]);
    const [genError, setGenError] = useState("");
    const [selectedProductList, setSelectedProductList] = useState([]);

    const createProductList = (prodList, selProdList, saveList = false) => {
        if (props.discountValue <= 0 && selProdList.length > 0) {
            selProdList = [];
            props.saveSelProductList(selProdList);
        }
        const processProdList = prodList.map(product => {
            const selProd = selProdList.find(x => x.PID === product.PID);
            // const product = selProdList.find(x => x.PID === prod.PID) || prod;
            product.prodDiscVal = (selProd && selProd.prodDiscVal) || "";
            if (props.couponType === 'value' && product.prodDiscVal > props.discountValue) {
                product.errorText = "Product discount amount should be less than discountValue."
            } else if (props.couponType === 'percentage' && (product.prodDiscVal > 100 || product.prodDiscVal > props.discountValue)) {
                product.errorText = "Product discount percentage should be less than discountValue."
            }
            return product;
        });

        if (saveList) {
            // setProductList(processProdList);
            // setSelectedProductList(selProdList);
            props.saveSelProductList(selProdList);
        } else {
            setProductList(processProdList);
            setSelectedProductList(selProdList);
        }
        return true;
    }
    const selectProduct = (product, prodDiscVal = 0, removeSelect = false) => {
        const selProdList = [];
        if (removeSelect) {
            console.log('helloooo')
            selectedProductList.forEach(prod => {
                if (prod.PID !== product.PID) {
                    selProdList.push(prod);
                }
            });
            // const newProdList=productList.map(prod=>{
            //     if()
            // })
            // console.log(selProdList);
            createProductList(productList, selProdList, true)
        } else if (props.couponType === 'value' && prodDiscVal > props.discountValue - 1) {
            product.errorText = "Product discount amount should be less than discountValue."
        } else if (props.couponType === 'percentage' && (prodDiscVal > 99 || prodDiscVal > props.discountValue - 1)) {
            console.log('123')
            product.errorText = "Product discount percentage should be less than discountValue."
        } else {
            product.prodDiscVal = prodDiscVal;
            selectedProductList.forEach(prod => {
                if (prod.PID !== product.PID) {
                    selProdList.push(prod);
                }
            });
            selProdList.push(product);
            createProductList(productList, selProdList, true)
        }

    }
    useEffect(() => {
        if(props.productList.length<=0){
            setGenError("* Unable to fetch product list. please try after sometimes or refresh the page.")
        }else{
            setGenError("");
        }
        createProductList(props.productList, props.selectedProductList)
        console.log(props.productList);
        // setProductList(props.productList);
        // setSelectedProductList(props.selectedProductList);
    }, [props.productList, props.selectedProductList, props.discountValue, props.couponType]);


    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    return (<>
        <Box x={{ flexGrow: 1 }}>
            <Typography variant="h6">Select products on which coupon is to be applied</Typography>
            {genError?<span style={{ color: 'red' }}>{genError}</span>:null}
            <Grid sx={{ flexGrow: 1, maxHeight: 300, overflowY: "auto", marginTop: 1 }} container spacing={1} direction={'row'} justifyContent={'flex-start'} alignItems={'center'} >
                {
                    productList.map(product => {
                        // console.log(prod);
                        // const product = selectedProductList.find(x => x.PID === prod.PID) || prod;
                        // console.log('----', product);
                        console.log(product.prodDiscVal);
                        return (<>
                            <Grid item md={4}>

                                <Box sx={{ border: 1, borderColor: "#949494", borderRadius: 1, padding: 1 }}>
                                    <Stack>
                                        <FormControlLabel sx={{ fontSize: 8 }}
                                            control={
                                                <Checkbox
                                                    key={`${product.PID}__cdForm_prod_checkbox`}
                                                    // defaultChecked={product.prodDiscVal > 0}
                                                    checked={product.prodDiscVal > 0}
                                                    disabled={!(product.prodDiscVal > 0)}
                                                // disabled={!(props.discountValue > 0)}
                                                />
                                            }
                                            label={<span style={{ fontSize: 14 }}>{product.product_name}</span>}
                                            onClick={(event) => {
                                                console.log(event)
                                                if (event.target.checked === false) {
                                                    selectProduct(product, product.prodDiscVal, true)
                                                }
                                            }}
                                        />

                                        <TextField
                                            disabled={!(props.discountValue > 0)}
                                            fullWidth
                                            label={props.couponType === 'value' ? 'Discount Amount' : 'Discount Percentage'}
                                            id={`${product.PID}__cdForm_prod_textfield`}
                                            name={`${product.PID}__cdForm_prod_textfield`}
                                            type="number"
                                            variant="standard"
                                            autoComplete="off"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            InputProps={
                                                props.couponType === 'value' ? { startAdornment: <InputAdornment position="start">₹</InputAdornment> } :
                                                    { endAdornment: <InputAdornment position="end">%</InputAdornment> }
                                            }
                                            onChange={(event) => {
                                                console.log(event.target.value);
                                                selectProduct(product, event.target.value)
                                            }}
                                            value={product.prodDiscVal}
                                        // error={errors.couponCode && touched.couponCode ? true : false}
                                        // helperText={errors.couponCode && touched.couponCode ? <span style={{ color: 'red' }}>{`${errors.couponCode}`}</span> : null}
                                        />
                                    </Stack>
                                </Box>
                            </Grid>
                        </>)

                    })}
            </Grid>
        </Box>
    </>)

}
export default AddProduct;





